body {
  margin: 0;
  width: 100%;
  background-color: #fff;
  color: #212529;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.site {
  overflow: hidden;
}

.site-nav-header {
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: auto;
  min-height: calc(300vh);
}

.site-nav-header nav {
  position: relative;
  z-index: 2;
}

.site-nav-header .main-nav-column-wrap {
  box-shadow: 0px 3px 12px 0px rgb(136 136 136 / 26%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.s;
  border-top: #ffb31f solid 2px;
  border-bottom: #990099 solid 2px;
  background-color: #fff;
}

.site-nav-header .main-nav-column-wrap .logo {
  margin: -40px 0;
  height: 120px;
  width: auto;
}

.current-section {
  text-transform: uppercase;
  color:#cf0dcf;
  font-weight: bold;
  font-size: medium;
  visibility: hidden;
}

#page-nav {
  position: sticky; 
  position: -webkit-sticky; 
  top: 0;
  z-index: 1; 
}

.parent-header {
  padding: 0.5rem 0;
}

.parent-header .parent-logo {
  height: 1.5rem;
  margin-top: -1px;
}

.parent-header a {
  color: #663300;
  text-decoration: none;
  font-size: small;
}

.parent-header a:hover, .parent-header a:visited, .parent-header a:focus  {
  color: #663300;
}

.nav-link{
  color:#990099 !important;
  font-size: medium;
  font-weight: 600;
  text-transform: uppercase;
}

.site-content {
  margin: 0;
  height: 100%;
}

.temp-content {
  margin: 0;
  height: 100%;
}

.temp-content .temp-section, .site-content .top-section{
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-bottom: 0;
  padding: 35px 0 0;
  background-image: url(../images/section-bg@2x.png);
  background-position: center center;
  background-size: cover;
  background-repeat: repeat-x;
}

.temp-content .temp-section-bg, .site-content .top-section-bg {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background-image: url(../images/trans-fly-imani-bg@2x.png);
  background-position: 110% 0px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.site-content .top-section-feature-img {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background-image: url(../images/fly-imani@2x.png);
  background-position: 120% 0;
  background-size: 65%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.temp-section {
  margin-top: -30px;
}

.top-section, .white-section, .purple-section, .pink-section {
  border-top: #990099 1px solid;
}

.top-section .container {
  margin-top: 130px;
  padding-bottom: 65px;
}

.white-section {
  background-color: #fff;
}

.white-section .container, .pink-section .container, .purple-section .container {
  /* margin-top: 80px;  */
  padding-bottom: 65px;
}

.purple-section {
  background-color: #d485d4;
  background-image: url(../images/sections-3-bg@2x.png);
  background-position: center bottom;
  background-size:cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.purple-section .container {
  margin-top: 80px;
}

.pink-section {
  background-color: #f3c1ef;
  background-image: url(../images/sections-5-bg@2x.png);
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.pink-section .container {
  padding-top: 65px;
}

.section-column {
  min-height: 360px;
  position: relative;
}

.btn-primary {
  color: #fff;
  background-color: #990099;
  border-color: #990099;
}

.btn-primary:hover, .btn-primary:visited, .btn-primary:focus, .btn-primary:disabled {
  color: #fff;
  background-color: #cf0dcf;
  border-color: #cf0dcf;
}

a, a:hover, a:visited, a:focus, a:disabled {
  color:#990099;
}

.logo-img {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

#allsteps .accordion-item {
  border-bottom: #990099 1px solid;
  background-color: #f3c1ef;
}

#allsteps .accordion-collapse {
  border-top: #990099 1px solid;
  background-color: #f3c1ef;
}

#allsteps .accordion-button, #allsteps .accordion-item {
  background-color: #f3c1ef !important;
}

/* .author-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  overflow: hidden;
}

.contact-img {
  width: 100%;
  margin: 0;
} */
.author-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  overflow: hidden;
}

.contact-img {
  width: 75%;
  margin: 0;
}

.book-section {
  margin: 0;
}

.accordion-button::after {
  content: none !important;
}

.accordion-button  {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  background-color: #fff !important;
  color: #990099 !important;
}

.accordion-button::before {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 10px;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}

#contact-form em {
  font-size: medium;
  color: crimson;
  padding-left: 10px;
}

footer {
  border-bottom: #ffb31f solid 2px;
  border-top: #990099 solid 2px;
  background-color: #fff;
  width: 100%;
}

@media (max-width: 769px) {
  .site-nav-header {
    min-height: calc(600vh);
  }

  .current-section {
    visibility: visible;
  }

  .temp-content .temp-section, .site-content .top-section{
    padding: 0;
  }

  .temp-content .temp-section-bg, .site-content .top-section-bg {
    background-position: 50% 0px;
    background-size: 200%;
  }

  .site-content .top-section-feature-img {
    background: none;
  }

  .author-img {
    position: relative !important;
    width: 100% !important;
  }

  .logo-img, .contact-img {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
  
}

@media (max-width: 1199px) and (min-width: 769px) {
  .author-img {
    width: 35%
  }
}